export { matchers } from './client-matchers.js';

			export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')];

			export const server_loads = [];

			export const dictionary = {
	"/": [2],
	"/account-deletion": [3],
	"/components": [4],
	"/d/[code]": [5],
	"/entity": [6],
	"/game": [7],
	"/kpis": [8],
	"/playground": [9],
	"/privacy-policy": [10],
	"/reset-password/[secret]": [11],
	"/stats": [12],
	"/stripe/[sessionId]": [13],
	"/user-journeys": [14],
	"/user-journeys/[query]": [15]
};

			export const hooks = {
				handleError: (({ error }) => { console.error(error) }),
			};